import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CustomEase } from "gsap/CustomEase";
import { ExpoScaleEase, SlowMo } from "gsap/EasePack";

gsap.registerPlugin(ScrollTrigger, CustomEase, ExpoScaleEase, SlowMo);

export default class Gsap {
  constructor(element) {
    this.element = element;
    this.variant = this.element.getAttribute("data-variant"); // Obtenez la valeur de l'attribut data-variant
    this.init();
  }

  init() {
    if (this.variant === "slide-left") {
      const conteneur = this.element.querySelectorAll(".conteneur");
      if (conteneur) {
        gsap.fromTo(conteneur, { x: "-130%" }, {
          ease: "sine.inOut",
          x: "0%", // Centrez l'élément lorsque le haut de l'élément atteint le centre de la fenêtre
          scrollTrigger: {
            trigger: conteneur,
            start: "10% 90%", 
            end: "center 75%",
            scrub: 2,
            toggleActions: "restart pause pause reverse", // Déclenche l'animation inverse lorsque l'utilisateur remonte en haut de la page
          },
        });
      }
    }
    if (this.variant === "slide-right") {
      const conteneur = this.element.querySelectorAll(".conteneur");
      if (conteneur) {
        gsap.fromTo(conteneur, { x: "130%" }, { // Changez cette ligne
          ease: "sine.inOut",
          x: "0%", // Centrez l'élément lorsque le haut de l'élément atteint le centre de la fenêtre
          scrollTrigger: {
            trigger: conteneur,
            start: "10% 90%", 
            end: "center 75%",
            scrub: 2,
            toggleActions: "restart pause pause reverse", // Déclenche l'animation inverse lorsque l'utilisateur remonte en haut de la page
          },
        });
      }
    }
  }
}

// Code pour initialiser la classe Gsap
document.addEventListener("DOMContentLoaded", function () {
  const elementsToAnimate = document.querySelectorAll("[data-variant]");
  elementsToAnimate.forEach((element) => {
    new Gsap(element);
  });
});
