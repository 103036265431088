import Carousel from './components/Carousel';
import YouTube from './components/YouTube';
import Lottie from './components/Lottie';
import Scrolly from './components/Scrolly';
import Modale from './components/Modale';
import Form from './components/Form';
import Remplissage from './components/Remplissage';
import RandomClipPath from './components/RandomClipPath';
import Cache from './components/Cache';
import Gsap from './components/Gsap';
import Header from './components/Header';

export default class ComponentFactory {
  constructor() {
    this.componentInstances = [];
    this.componentList = {
      Carousel,
      YouTube,
      Lottie,
      Scrolly,
      Modale,
      Form,
      Remplissage,
      RandomClipPath,
      Cache,
      Gsap,
      Header,
    };
    this.init();
  }
  init() {
    const components = document.querySelectorAll('[data-component]');

    for (let i = 0; i < components.length; i++) {
      const element = components[i];
      const componentName = element.dataset.component;

      if (this.componentList[componentName]) {
        const instance = new this.componentList[componentName](element);
        this.componentInstances.push(instance);
      } else {
        console.log(`La composante ${componentName} n'existe pas`);
      }
    }
  }
}
