import lottie from 'lottie-web';

export default class Lottie {
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    const animationPath = this.element.getAttribute('data-path');

    // Chargez l'animation, mais ne l'autorisez pas à démarrer automatiquement
    this.animation = lottie.loadAnimation({
      container: this.element,
      renderer: 'svg',
      loop: false,
      autoplay: false, // Ne démarre pas automatiquement l'animation
      path: animationPath,
    });

    // Écoutez l'événement d'intersection pour démarrer l'animation lorsque l'élément devient visible
    const observer = new IntersectionObserver(this.watch.bind(this));
    observer.observe(this.element);
  }

  // Méthode pour démarrer l'animation
  startAnimation() {
    if (this.animation) {
      this.animation.play();
    }
  }

  // Méthode appelée lorsqu'un élément entre ou sort de la zone visible
  watch(entries) {
    for (let i = 0; i < entries.length; i++) {
      const entry = entries[i];
      if (entry.isIntersecting) {
        // Démarrer l'animation lorsque l'élément devient visible
        this.startAnimation();
      }
    }
  }
}
