/** Composante Form */
export default class Form {
    constructor(element) {
      this.element = element;
      this.formElements = this.element.elements;
      this.confirmation = document.querySelector('.form__confirmation');
      this.init();
    }
    /**
     * Méthode d'initialisation de la composante
     */
    init() {
      this.element.setAttribute('novalidate', '');
  
      for (let i = 0; i < this.formElements.length; i++) {
        const input = this.formElements[i];
  
        if (input.required) {
          input.addEventListener('input', this.validateInput.bind(this));
        }
      }
  
      this.element.addEventListener('submit', this.onSubmit.bind(this));
    }
    /**
     * Méthode appelée lors de la soumission du formulaire
     * @param {event} event - Le click du bouton pour envoyer le formulaire
     */
    onSubmit(event) {
      event.preventDefault();
  
      if (this.validate()) {
        // envoie ajax du formulaire
        this.showConfirmation();
      } else {
      }
    }
  
    /**
     * Vérifie si tous les champs sont valides
     */
    validate() {
      let isValid = true;
  
      for (let i = 0; i < this.formElements.length; i++) {
        const input = this.formElements[i];
  
        if (input.required && !this.validateInput(input)) {
          isValid = false;
        }
      }
      return isValid;
    }
    /**
     * Valide un champ de saisie individuel
     * @param {event} event - click sur un champ form
     */
    validateInput(event) {
      const input = event.currentTarget || event;
  
      if (input.validity.valid) {
        this.removeError(input);
      } else {
        this.addError(input);
      }
  
      return input.validity.valid;
    }
    /**
     * Ajoute la classe d'erreur à un champ de saisie si mal fait
     * @param {HTMLElement} input - l'élément html du champ form
     */
    addError(input) {
      const container =
        input.closest('[data-input-container]') || input.closest('.input');
      container.classList.add('error');
    }
    /**
     * Enlève la classe d'erreur à un champ de saisie
     * @param {HTMLElement} input - l'élément html du champ form
     */
    removeError(input) {
      const container =
        input.closest('[data-input-container]') || input.closest('.input');
      container.classList.remove('error');
    }
    /**
     * Affiche la confirmation d'envoi du formulaire
     */
    showConfirmation() {
      this.element.classList.add('is-sent');
      this.confirmation.classList.add('envoyer');
    }
  }
  