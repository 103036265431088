/** Composante Remplissage */
export default class Remplissage {
    /**
     * @param {HTMLElement} element - contient l'élément html contenant le data-component Remplissage
     */
    constructor(element) {
        this.element = element; // L'élément HTML contenant les lignes
        this.lines = element.querySelectorAll('.line'); // Sélectionne toutes les lignes
        this.windowHeight = window.innerHeight; // Hauteur de la fenêtre du navigateur

        // Initialise la composante Remplissage
        this.init();
    }

    init() {
        // Écoute les événements de défilement et de redimensionnement de la fenêtre
        window.addEventListener('scroll', () => {
            this.updateClipPath(); // Appelle la fonction de mise à jour lorsque vous faites défiler
        });

        window.addEventListener('resize', () => {
            this.windowHeight = window.innerHeight; // Met à jour la hauteur de la fenêtre en cas de redimensionnement
            this.updateClipPath(); // Appelle la fonction de mise à jour lors du redimensionnement
        });

        // Appel initial pour définir l'état initial
        this.updateClipPath();
    }

    updateClipPath() {
        // Parcourt chaque ligne
        this.lines.forEach((line, index) => {
            const rect = line.getBoundingClientRect(); // Obtient les coordonnées de la ligne par rapport à la fenêtre
            const isVisible = rect.top < this.windowHeight && rect.bottom >= 0; // Vérifie si la ligne est visible

            if (isVisible) {
                const scrollY = window.scrollY; // Position de défilement en pixels depuis le haut

                // Calcule la taille en fonction de la position de défilement pour rendre la ligne visible lorsque vous faites défiler vers le bas
                const size = 100 - Math.max(0, Math.min(100, ((scrollY - rect.top) / this.windowHeight) * 350));


                line.style.setProperty('--size', `${size}%`); // Définit la taille du remplissage
            } else {
                line.style.setProperty('--size', '100%'); // Masque la ligne si elle n'est pas visible
            }
        });
    }
}
