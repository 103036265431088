export default class Cache {
  constructor(element) {
      this.element = element;
      this.heroImage = this.element.querySelector('.hero-image');
      this.heroTitle = this.element.querySelector('.hero-title');
      this.videoContainer = this.element.querySelector('.video');

      // Ajoutez un gestionnaire d'événements pour suivre les changements de la taille de la fenêtre
      window.addEventListener('resize', () => {
          this.handleWindowResize();
      });

      // Exécutez initialement la gestion des éléments en fonction de la taille de la fenêtre actuelle
      this.handleWindowResize();
  }

  handleWindowResize() {
      // Vérifiez la largeur de la fenêtre à chaque frame
      if (window.innerWidth >= 1050) {
          // Ajoutez des gestionnaires d'événements au survol du div vidéo
          this.videoContainer.addEventListener('mouseenter', () => {
              this.hideElements(); // Masquer l'image et le titre
          });

          this.videoContainer.addEventListener('mouseleave', () => {
              this.showElements(); // Afficher à nouveau l'image et le titre
          });
      } else {
          // Retirez les gestionnaires d'événements si la largeur de la fenêtre est inférieure à 1050 pixels
          this.videoContainer.removeEventListener('mouseenter', () => {
              this.hideElements();
          });

          this.videoContainer.removeEventListener('mouseleave', () => {
              this.showElements();
          });
      }
  }


    hideElements() {
    // Appliquez une transformation pour déplacer les éléments vers la gauche
    this.heroImage.style.transform = 'translateX(-1300px)';
    this.heroTitle.style.transform = 'translateX(-1800px)';
    this.heroImage.style.transition = 'transform 0.4s ease-in-out';
    this.heroTitle.style.transition = 'transform 0.4s ease-in-out';
    this.videoContainer.style.left = '50%';
    this.videoContainer.style.transform = 'translate(-50%,0)';
    this.videoContainer.style.width = '80%';
    this.videoContainer.style.zindex = '995';
    this.videoContainer.style.setProperty('--height', '80vh');

        if(window.innerWidth <= 1150){
            this.videoContainer.style.setProperty('--height', '60vh');
        }

    }

    showElements() {
        this.heroImage.style.transform = 'translateX(0)';
        this.heroTitle.style.transform = 'translateX(0)';
        this.videoContainer.style.left = '65%';
        this.videoContainer.style.width = '45vw';
        this.videoContainer.style.transform = 'translate(-65%,0)';
        this.videoContainer.style.setProperty('--height', '55vh');

        if(window.innerWidth <= 1050){
            this.videoContainer.style.left = '0';
            this.videoContainer.style.transform = 'translate(0,0)';
        }
    }
}