/** Composante Modale */
export default class Modale {
  /**
   * @param {HTMLElement} element - contient l'élément html contenant le data-component Modale
   */
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    // Sélectionnez toutes les images à l'intérieur de l'élément
    const images = this.element.querySelectorAll('img');

    // Créez la modale
    const modal = document.createElement('div');
    modal.classList.add('modal');

    // Créez l'image dans la modale
    const modalImage = document.createElement('img');

    // Créez le bouton de fermeture
    const closeButton = document.createElement('button');
    closeButton.textContent = 'X';
    closeButton.classList.add('close-button');

    // Ajoutez le bouton de fermeture à la modale
    modal.appendChild(closeButton);

    // Ajoutez l'image à la modale
    modal.appendChild(modalImage);


    // Ajoutez la modale à la fin du document
    document.body.appendChild(modal);

    // Gérez l'événement de clic sur chaque image
    images.forEach((image) => {
      image.addEventListener('click', () => {
        modalImage.src = image.src.replace('.avif', '.webp');
        modalImage.alt = image.alt;
        modal.style.display = 'block';

        // Désactivez le défilement de la page
        document.documentElement.style.overflow = 'hidden';
      });
    });


      // Gérez l'événement de clic sur le bouton de fermeture
      closeButton.addEventListener('click', () => {
      modal.style.display = 'none';

      // Réactivez le défilement de la page
      document.documentElement.style.overflowY = 'auto';
    });

    // Gérez l'événement de clic en dehors de la modale pour la fermer
    modal.addEventListener('click', (event) => {
      if (event.target === modal) {
        modal.style.display = 'none';

        // Réactivez le défilement de la page
        document.documentElement.style.overflowY = 'auto';
      }
    });

    // Fermez la modale avec la touche "Escape"
    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        modal.style.display = 'none';

        // Réactivez le défilement de la page
        document.documentElement.style.overflowY = 'auto';
      }
    });
  }
}