import ComponentFactory from './ComponentFactory';
import Icons from './utils/Icons';
import Lenis from '@studio-freight/lenis';

class Main {
  constructor() {
    this.init();
  }

  init() {
    document.documentElement.classList.add('has-js');

    new ComponentFactory();

    Icons.load();
  }
}
new Main();


 //form ici 
 
 const formulaire = document.getElementById("myForm");
 
 if (formulaire){
  formulaire.addEventListener("submit", function (e) {
    e.preventDefault(); // Empêche la soumission du formulaire par défaut
  
    // Collectez les données du formulaire ici
    const formData = new FormData(this);

    // Créez un objet JavaScript à partir de formData
    const formDataObj = {};
      formData.forEach((value, key) => {
          formDataObj[key] = value;
      });
   
    // Convertissez l'objet en JSON
    const formDataJSON = JSON.stringify(formDataObj);
  
    // Effectuez une requête AJAX pour envoyer les données
    // Indiquez l'URL de la fonction
    // Spécifiez l'adresse e-mail où les données doivent être envoyées
    fetch("https://send-email.amambo008.workers.dev/?emailReciver=jordandallaire23@gmail.com", {
       method: "POST",
       body: formDataJSON,
       headers: {
        "Content-Type": "application/json"
       }
    })
    .then(response => {
       if (response.ok) {
          // Le traitement a réussi (code de statut 200)
          // Code qui doit être exécuté quand l'e-mail a été envoyé
          console.log("Envoi de l'e-mail réussi");
  
         // Réinitialiser le formulaire
         document.getElementById("myForm").reset();
         
         // Afficher le message de succès
         document.querySelector(".form__confirmation").style.display = "block";
  
       } 
       else {
          // Le traitement a échoué avec un code de statut différent de 200
          // Code qui doit être exécuté quand un problème se produit avec la requête
          throw new Error("Échec de la requête. Code de statut : " + response.status);
       }
    })
    .then(data => {
       // Traitement réussi, utilisez les données (par exemple, affichez la réponse du serveur)
       console.log("Succès !");
    })
    .catch(error => {
       // Gérez les erreurs ou affichez un message d'erreur
       console.error(error);
    });
  });
 }

const lenis = new Lenis()

lenis.on('scroll', (e) => {
  
})

function raf(time) {
  lenis.raf(time)
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)
