export default class YouTube {
    /**
     * @param {HTMLElement} element - contient l'élément html contenant le data-component YouTube
     */
    constructor(element) {
      this.element = element;
  
      this.videoContainer = this.element.querySelector('.js-video');
      this.poster = this.element.querySelector('.js-poster');
      this.videoId = this.element.dataset.videoId;
      this.autoplay = this.poster ? 0 : 1;
      this.playerReady = false;
      this.element.dataset.controls || 0; // récupère la valeur de data-controls, par défaut 0
      YouTube.instances.push(this);
  
      /**
       * Regarde s'il y a un id de video si oui lance le script sinon lance une erreur dans la console
       */
      if (this.videoId) {
        YouTube.loadScript();
      } else {
        console.error('vous devez spécifier un id');
      }
    }
  
    /**
     * Vérifie si le script API YouTube est en cours de chargement, et le charge s'il ne l'est pas déjà.
     */
    static loadScript() {
      if (!YouTube.scriptIsLoading) {
        YouTube.scriptIsLoading = true;
  
        const script = document.createElement('script');
        script.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(script);
      }
    }
  
    /**
     *Initialise la lecture de la vidéo
     */
    init() {
      this.initPlayer = this.initPlayer.bind(this);
  
      if (this.poster) {
        this.element.addEventListener('click', this.initPlayer.bind(this));
      } else {
        this.initPlayer();
      }
    }
  
    /**
     * initialise le lecteur vidéo avec les paramètres spécifiés
     */
  
    initPlayer(event) {
      if (event) {
        this.element.removeEventListener('click', this.initPlayer);
      }
  
      const controls = this.element.dataset.controls === '0' ? 1 : 0; //transformation en chaine de caractère
  
      this.player = new YT.Player(this.videoContainer, {
        height: '100%',
        with: '100%',
        videoId: this.videoId,
        playerVars: {
          rel: 0,
          autoplay: this.autoplay,
          controls: controls,
          mute: 1,
          disablekb: 1, // Désactive également le clavier pour empêcher les raccourcis clavier
          enablejsapi: 1, // Active l'API JavaScript du lecteur pour le contrôler via JavaScript
          modestbranding: 1, // Supprime le logo YouTube
        },
        events: {
          onReady: () => {
            this.playerReady = true;
            const observer = new IntersectionObserver(this.watch.bind(this), {
              rootMargin: '0px 0px 0px 0px',
            });
            observer.observe(this.element);
          },
          onStateChange: (event) => {
            if (event.data == YT.PlayerState.PLAYING) {
              YouTube.pauseAll(this);
            } else if (event.data == YT.PlayerState.ENDED) {
              this.player.seekTo(0);
              this.player.pauseVideo();
            }
          },
        },
      });
    }
  
    /**
     * Arrête la vidéo en dehors de la zone d'observation
     * @param {Array} entries - Décrit chaque élément observé et son état d'intersection avec la zone root margin
     */
    watch(entries) {
      if (this.playerReady && !entries[0].isIntersecting) {
        this.player.pauseVideo();
      }
    }
  
    /**
     * initialise toutes les instances de la classe YouTube sur la page.
     */
    static initAll() {
      document.documentElement.classList.add('is-video-ready');
  
      for (let i = 0; i < YouTube.instances.length; i++) {
        const instance = YouTube.instances[i];
        // initalise la composante YouTube
        instance.init();
      }
    }
  
    /**
     * Met en pause toutes les vidéos de toutes les instances de la classe YouTube, sauf celle passée en paramètre.
     */
    static pauseAll(currentInstance) {
      for (let i = 0; i < YouTube.instances.length; i++) {
        const instance = YouTube.instances[i];
        if (instance.playerReady && instance !== currentInstance) {
          instance.player.pauseVideo();
        }
      }
    }
  }
  
  YouTube.instances = [];
  window.onYouTubeIframeAPIReady = YouTube.initAll;
  