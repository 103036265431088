/** Composante RandomClipPath */
export default class RandomClipPath {
    constructor(element) {
        this.element = element;
        this.clipPathValues = [
            "polygon(100% 0, 100% 90%, 89% 100%, 0 89%, 0 44%, 0% 0%)",
            "polygon(14% 0, 75% 0%, 53% 48%, 75% 100%, 15% 100%, 6% 50%)",
            "polygon(0 20%, 100% 22%, 99% 52%, 55% 54%, 19% 54%, 0 53%)",
            "polygon(40% 0, 68% 0, 68% 100%, 65% 100%, 39% 100%, 39% 100%)",
            "polygon(25% 0%, 75% 0%, 85% 29%, 73% 53%, 57% 76%, 26% 88%)",
        ];
        this.lastRandomIndex = -1; // Initialisation avec une valeur non utilisée
        this.init();

    }

    init() {
        // Utilisez setInterval pour changer le clip-path toutes les 8 secondes
        setInterval(() => {
            this.setRandomClipPath();
        }, 2000); // 2000 millisecondes équivalent à 8 secondes
    }

    // Fonction pour définir un clip-path aléatoire sans répétition
    setRandomClipPath() {
        let randomIndex;

        do {
            randomIndex = Math.floor(Math.random() * this.clipPathValues.length);
        } while (randomIndex === this.lastRandomIndex);

        this.lastRandomIndex = randomIndex;

        // Récupérez la valeur de clip-path correspondante
        const randomClipPath = this.clipPathValues[randomIndex];

        // Appliquez le clip-path à l'élément img
        this.element.style.clipPath = randomClipPath;
    }
}
